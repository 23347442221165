import { normalizeURL, normalizeSiteURL } from "utils"

import { Facebook, Insta, Vk, Telegram, Vimeo, Youtube } from "ui/icons"
import { Logo } from "ui/logo"
import { Container } from "ui/container"
import { useGlobalState } from "@app/contexts"

import styles from "./header.module.scss"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
const Header = () => {
  const {
    logo,
    logoAlt,
    name: photographerName,
    facebook,
    instagram,
    vkontakte,
    telegram,
    vimeo,
    youtube,
    phone,
    email,
    site,
    photographerSite,
  } = useGlobalState()

  return (
    <Container>
      <header className={styles["header"]}>
        <div className={styles["logo"]}>
          {logo && (
            <Logo
              logo={logo}
              logoAlt={logoAlt}
              photographerName={photographerName}
              photographerSite={photographerSite}
              clickable={false}
            ></Logo>
          )}
        </div>
        <div className={styles["info"]}>
          {!logo && (
            <figure className={styles["avatar"]}>
              <figcaption className={styles["name"]}>{photographerName}</figcaption>
            </figure>
          )}

          <div className={styles["contacts"]}>
            {phone && (
              <a className={styles["contact"]} href={`tel:${phone}`}>
                {phone}
              </a>
            )}
            {email && (
              <a className={styles["contact"]} href={`mailto:${email}`}>
                {email}
              </a>
            )}
            {site && (
              <a className={styles["contact"]} href={normalizeURL(site)} target="_blank" rel="noopener noreferrer">
                {normalizeSiteURL(site)}
              </a>
            )}
          </div>

          <ul className={styles["social-networks"]}>
            {instagram && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(instagram)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Insta width={26} height={26} />}
                    dark={<Insta fill="#fff" width={26} height={26} />}
                  />
                </a>
              </li>
            )}
            {facebook && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(facebook)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Facebook width={26} height={26} />}
                    dark={<Facebook fill="#fff" width={26} height={26} />}
                  />
                </a>
              </li>
            )}
            {vkontakte && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(vkontakte)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Vk width={26} height={26}></Vk>
                </a>
              </li>
            )}
            {telegram && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(telegram)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Telegram width={26} height={26}></Telegram>
                </a>
              </li>
            )}
            {vimeo && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(vimeo)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Vimeo width={26} height={26}></Vimeo>
                </a>
              </li>
            )}
            {youtube && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(youtube)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Youtube width={26} height={26}></Youtube>
                </a>
              </li>
            )}
          </ul>
        </div>
      </header>
    </Container>
  )
}
export { Header }
