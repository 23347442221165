import { getCoverURLBySize } from "./get-cover-url-by-size"

interface GetResponsiveSizesString {
  cover: string
  size: string
  imgFormat?: string
}

function getResponsiveSizesString({ cover, size, imgFormat = "" }: GetResponsiveSizesString): string {
  const defaultResolutionCoverImage = getCoverURLBySize(cover, size)
  const highResolutionCoverImage = getCoverURLBySize(cover, `${size}@2`)

  if (!highResolutionCoverImage) {
    return `${defaultResolutionCoverImage}${imgFormat}`
  }

  if (highResolutionCoverImage) {
    return `${defaultResolutionCoverImage}${imgFormat} 1x, ${highResolutionCoverImage}${imgFormat} 2x`
  }
}

export { getResponsiveSizesString }
