import React from "react"

import { normalizeURL, normalizeSiteURL } from "@app/utils"
import { useGlobalState } from "@app/contexts"

import { Facebook, Insta, Vk, Telegram, Vimeo, Youtube } from "@app/ui/icons"
import { Container } from "@app/ui/container"
import { Logo } from "@app/ui/logo"

import styles from "./footer.module.scss"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

interface FooterProps {
  clickableLogo?: boolean
}

const Footer = ({ clickableLogo = false }: FooterProps) => {
  const {
    facebook,
    instagram,
    vkontakte,
    telegram,
    vimeo,
    youtube,
    phone,
    email,
    site,
    logo,
    logoAlt,
    name: photographerName,
    site: photographerSite,
  } = useGlobalState()

  return (
    <footer className={styles["footer"]}>
      <Container>
        <div className={styles["info"]}>
          <div className={styles["logo"]}>
            <Logo
              logo={logo}
              logoAlt={logoAlt}
              photographerName={photographerName}
              photographerSite={photographerSite}
              clickable={clickableLogo}
              location="footer"
              align="center"
            ></Logo>
          </div>
          <ul className={styles["contacts"]}>
            {phone && (
              <li className={styles["item"]}>
                <a className={styles["contact"]} href={`tel:${phone}`}>
                  {phone}
                </a>
              </li>
            )}
            {email && (
              <li className={styles["item"]}>
                <a className={styles["contact"]} href={`mailto:${email}`}>
                  {email}
                </a>
              </li>
            )}
            {site && (
              <li className={styles["item"]}>
                <a className={styles["contact"]} href={normalizeURL(site)} target="_blank" rel="noopener noreferrer">
                  {normalizeSiteURL(site)}
                </a>
              </li>
            )}
          </ul>
          <ul className={styles["social-networks"]}>
            {instagram && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(instagram)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Insta width={26} height={26} />}
                    dark={<Insta width={26} height={26} fill="#fff" />}
                  />
                </a>
              </li>
            )}
            {facebook && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(facebook)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Facebook width={26} height={26} />}
                    dark={<Facebook fill="#fff" width={26} height={26} />}
                  />
                </a>
              </li>
            )}
            {vkontakte && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(vkontakte)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper light={<Vk width={26} height={26} />} dark={<Vk width={26} height={26} fill="#fff" />} />
                </a>
              </li>
            )}
            {telegram && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(telegram)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Telegram width={26} height={26} />}
                    dark={<Telegram width={26} height={26} fill="#fff" />}
                  />
                </a>
              </li>
            )}
            {vimeo && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(vimeo)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Vimeo width={26} height={26} />}
                    dark={<Vimeo width={26} height={26} fill="#fff" />}
                  />
                </a>
              </li>
            )}
            {youtube && (
              <li className={styles["social-network"]}>
                <a
                  className={styles["social-link"]}
                  href={normalizeURL(youtube)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper
                    light={<Youtube width={26} height={26} />}
                    dark={<Youtube width={26} height={26} fill="#fff" />}
                  />
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className={styles["copyright"]}>
          <p className={styles["text"]}>
            ©{new Date().getFullYear()} {photographerName}
          </p>
          <p className={styles["text"]}>
            <a
              href={`https://vigbo.com/galleries?utm_source=vigbo.gallery&utm_medium=footer&utm_campaign=copyright_gallery_lp`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Gallery by Vigbo
            </a>
          </p>
        </div>
      </Container>
    </footer>
  )
}

export { Footer }
